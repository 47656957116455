














import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	name: 'MyNeobanxPage',

	props: {
		loginInfo: {}
	},

	setup(props, { root }) {
		const loginInfo = props.loginInfo as { firstName: string }

		if (loginInfo.firstName == '') {
			root.$router.replace('/login?goto=/my-neobanx')

			return
		}	

		return {}
	}
})
